$(document).ready(function() {

	//	Uploader
    if ($(".dropzone").length > 0) 
    {		
		Dropzone.autoDiscover = false;
	
		var myDropzone = new Dropzone("form.dropzone", 
		{
				dictDefaultMessage: "Klik hier of sleep bestanden in dit vlak om te uploaden",
		});		
    }
    
	
	//	Datepicker
	$('.date-picker').datepicker({format:'dd-mm-yyyy', language:'nl', weekStart:1}).on('changeDate', function(e) 
	{		
		$(this).datepicker('hide');
	});
	
	//	Linkable table row
	$("tr[data-link] td").click(function() 
	{
		location = $(this).parents('tr').data('link');
	});



	//	Hide flash mesage
	if ($(".alert.disapear").length > 0) 
	{	  
	  setTimeout(function() 
	  {		  
		  $(".alert").slideUp();  
	  }, 3000);	  
  	}
  	
  	
  	$("input[name='multiple']").change(function()
  	{
	 	var selected = $('input[name=multiple]:checked').val();	
	 		 
	 	$('.request-form').addClass('hidden');
	 	
	 	type = selected == 1 ? 'multiple' : 'single';
	 	
	 	$('#'+type+'-request-form').removeClass('hidden');	 
	 	
	 	
  	});
 
});
